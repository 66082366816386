import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/lasparrow.png";
import projImg2 from "../assets/img/optimaleye.PNG";
import projImg3 from "../assets/img/midaaschool.PNG";
import projImg4 from "../assets/img/instagrazz.JPG";
import projImg5 from "../assets/img/whatsapx.JPG";
import projImg6 from "../assets/img/laundrmanpickup.JPG";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg7 from "../assets/img/delaplush.JPG";
import projImg8 from "../assets/img/bridaltrains.JPG";
import projImg9 from "../assets/img/lotterydapp.JPG";
import projImg10 from "../assets/img/lamachat.JPG";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import navIcon5 from '../assets/img/nav-icon5.svg';

export const Projects = () => {

  const projects = [
    {
      title: "Sparrow Network",
      description: "A social-ecommerce network using procedural PHP",
      imgUrl: projImg1,
      appUrl: "https://sn.potfolio.com.ng",
    },
    {
      title: "Optimal Eye Centre",
      description: "An eye clinic for lens and eye surgery. Developed using laravel",
      imgUrl: projImg2,
      appUrl: "https://oe.potfolio.com.ng",
    },
    {
      title: "Midaa School",
      description: "A crypto institute. Developed using laravel",
      imgUrl: projImg3,
      appUrl: "https://ms.potfolio.com.ng",
    },
    {
      title: "Laundryman Pickup",
      description: "A laundry booking service developed with procedural PHP and Bootstrap",
      imgUrl: projImg6,
      appUrl: "https://lp.potfolio.com.ng",
    },
    {
      title: "DelaPlush Stores",
      description: "An online cosmetic store for all kinds of cosmestics",
      imgUrl: projImg7,
      appUrl: "https://dp.potfolio.com.ng",
    },
    {
      title: "Bridal Trains",
      description: "Bridal trains is an online photo gallery for marriages",
      imgUrl: projImg8,
      appUrl: "https://bt.potfolio.com.ng",
    },
    
  ];

  const reactjs = [
    {
      title: "Whatsapp Clone",
      description: "This project is a Whatsapp clone and is not to be used for commercial purposes",
      imgUrl: projImg5,
      appUrl: "https://whatsapp-v2-commodore07.vercel.app",
    },
    {
      title: "Instagram Clone",
      description: "This project is an Instagram clone and is not to be used for commercial purposes",
      imgUrl: projImg4,
      appUrl: "https://myinstagram-liard.vercel.app",
    },
    {
      title: "WEB 3.0 Lottery Dapp",
      description: "Explore the world of blockchain technology. This is a decentralized blockchain lottery app",
      imgUrl: projImg9,
      appUrl: "https://lotterydapp-blond.vercel.app/",
    },
    {
      title: "Chat Box",
      description: "Lama chat box. Create an account, login and chat with friends",
      imgUrl: projImg10,
      appUrl: "https://lamachatz.vercel.app",
    },
    
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>These projects include both procedural and object oriented ways of programming. They include full stack softwares written with PHP, REACT, NEXT.JS, HTML, CSS, STYLED COMPONENTS, JAVASCRIPT ETC.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">PHP</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">JAVASCRIPT (REACT, VUE)</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">PYTHON</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    <Row>
                        {
                          reactjs.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Coming soon...</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
